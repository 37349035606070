<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <form class="form" @submit.stop.prevent="formOnsubmit()">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h3
                      class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                    >
                      Edit Koleksi
                    </h3>

                    <div class="row"> 
                      <div class="col-md-4">
                    <b-form-group id="input-group-photo">
                      <label class="col-xl-3 col-lg-5 col-form-label pl-0 pt-0"
                        >Gambar</label
                      >
                      <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                        <div
                          class="image-input image-input-outline"
                          id="kt_profile_avatar"
                        >
                          <div class="image-input-wrapper">
                            <img :src="photo" alt="" />
                          </div>
                          <label
                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="change"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Change avatar"
                          >
                            <i class="fa fa-pen icon-sm text-muted"></i>
                            <input
                              type="file"
                              name="profile_avatar"
                              accept=".png, .jpg, .jpeg"
                              @change="onFileChange($event)"
                            />
                            <input type="hidden" name="profile_avatar_remove" />
                          </label>
                          <span
                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="cancel"
                            data-toggle="tooltip"
                            title="Cancel avatar"
                          >
                            <i class="ki ki-bold-close icon-xs text-muted"></i>
                          </span>
                          <span
                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="remove"
                            data-toggle="tooltip"
                            title="Remove avatar"
                            @click="current_photo = null"
                          >
                            <i class="ki ki-bold-close icon-xs text-muted"></i>
                          </span>
                        </div>
                      </div>
                    </b-form-group>
                    </div>
                      <div class="col-md">
                    <b-form-group
                      id="input-group-name"
                      label="Nama:"
                      label-for="input-name"
                    >
                      <b-form-input
                        id="input-name"
                        v-model="form.name"
                        placeholder="Nama Koleksi"
                      ></b-form-input>
                      <small class="text-danger">{{ error.name }}</small>
                    </b-form-group>
                    </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="$router.push('/masters/collections')"
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      form: {
        name: "",
        image: null,
      },
      error: {
        name: "",
        image: null,
      },
      current_photo: null,
    };
  },
  methods: {
    async get() {
      this.form = await module.get('api/collections/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/masters/collections')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    },
    
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.image = "";
    },

    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, "api/collections/" + this.$route.params.id);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(this.error,response.error.errors);
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/masters/collections");
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Koleksi", route: "/masters/collections" },
      { title: "Edit Koleksi" },
    ]);
    this.get()
  },

  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>